:root {
  .ph-color {
    color: #808080;
  }

  .text-bold {
    font-weight: 700;
  }

  .text-bold-600 {
    font-weight: 600;
  }

  .text-bold-500 {
    font-weight: 500;
  }

  .text-bold-400 {
    font-weight: 400;
  }

  .text-error {
    color: var(--highlight-color-invalid);
  }

  .text-center {
    text-align: center;
  }

  .text-success {
    color: var(--ion-color-success);
  }

  .text-warning {
    color: var(--ion-color-warning);
  }

  .text-danger {
    color: var(--ion-color-danger);
  }

  .alert-danger .alert-sub-title {
    color: var(--ion-color-danger);
  }

  .bg-100 {
    --background: var(--ion-color-step-100, #e6e6e6);
  }

  .input-clear-icon.sc-ion-input-ios,
  .input-clear-icon.sc-ion-input-md {
    color: var(--ion-color-medium-tint);
  }

  .font-size-12 {
    font-size: 12px;
  }

  .pa-0 {
    padding: 0;
    padding-inline: 0;
  }

  .ps-05 {
    padding-left: 8px;
  }

  .ps-1 {
    padding-left: 16px;
  }

  .ps-2 {
    padding-left: 32px;
  }

  .pt-0 {
    padding-top: 0;
  }

  .pt-05 {
    padding-top: 8px;
  }

  .pt-1 {
    padding-top: 16px;
  }

  .pt-2 {
    padding-top: 32px;
  }

  .pb-0 {
    padding-bottom: 0;
  }

  .pb-05 {
    padding-bottom: 8px;
  }
  .pb-1 {
    padding-bottom: 16px;
  }

  .pb-2 {
    padding-bottom: 32px;
  }

  .ps-0 {
    padding-left: 0;
  }

  .ps-05 {
    padding-left: 8px;
  }

  .ps-1 {
    padding-left: 16px;
  }

  .ps-2 {
    padding-left: 32px;
  }

  .pe-0 {
    padding-right: 0;
  }

  .pe-05 {
    padding-right: 8px;
  }

  .pe-1 {
    padding-right: 16px;
  }

  .pe-2 {
    padding-right: 32px;
  }

  .mt-0 {
    margin-top: 0;
  }

  .mt-05 {
    margin-top: 8px;
  }

  .mt-1 {
    margin-top: 16px !important;
  }

  .mt-2 {
    margin-top: 32px !important;
  }

  .mt-3 {
    margin-top: 48px !important;
  }

  .mb-0 {
    margin-bottom: 0;
  }

  .mb-05 {
    margin-bottom: 8px;
  }

  .mb-1 {
    margin-bottom: 16px;
  }

  .mb-2 {
    margin-bottom: 32px;
  }

  .ms-0 {
    margin-left: 0;
  }

  .ms-05 {
    margin-left: 8px;
  }

  .ms-1 {
    margin-left: 16px;
  }

  .ms-2 {
    margin-left: 32px;
  }

  .me-0 {
    margin-right: 0;
  }

  .me-05 {
    margin-right: 8px;
  }

  .me-1 {
    margin-right: 16px;
  }

  .me-2 {
    margin-right: 32px;
  }

  .ma-0 {
    margin: 0;
    margin-inline: 0;
  }

  .ms-auto {
    margin-left: auto;
  }

  .me-auto {
    margin-right: auto;
  }

  ion-accordion .ion-accordion-toggle-icon {
    display: none;
  }

  ion-modal.ion-datetime-button-overlay::part(backdrop) {
    --backdrop-opacity: 0.2;
    z-index: 11;
  }

  ion-modal.ion-datetime-button-overlay::part(content) {
    z-index: 12;
  }

  .error-text {
    padding-bottom: 4px;
  }

  .button-white {
    background-color: #ffff;
  }

  .border-top {
    border-top: 0.55px solid var(--ion-color-step-200, #cccccc);
  }

  .logo {
    height: 25px;
    margin-top: 5px;
  }

  .empty-message {
    text-align: center;
    margin-top: 50px;
    padding-left: 32px;
    padding-right: 32px;
    color: var(--ion-color-medium);
  }

  .label {
    display: inline-block;
    color: #ffffff;
    padding: 2px 4px;
    text-transform: uppercase;
    font-size: 8px;
    font-weight: 700;
    border-radius: 3px;
    margin-bottom: 0;
    margin-right: 4px;
  }

  .input-plate input {
    text-transform: uppercase;
    font-weight: 600;
  }
  .input-plate input::placeholder {
    text-transform: capitalize !important;
    font-weight: normal !important;
  }
}

ion-app {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.text-uppercase {
  text-transform: uppercase;
}
